import React, {useEffect} from 'react'
import TopNav from "../Components/TopNav";
import {useTheme} from "@mui/material";
import {useThemeSettings} from "../Contexts/Hooks/useThemeSettings";
import Main from "../SharedComponents/Main";
import MainBody from "../SharedComponents/MainBody";
import About from "../Components/About";
import Education from "../Components/Education";
import Work from "../Components/Work";
import Skills from "../Components/Skills";
import ContactMe from "../Components/ContactMe";


const Layout = () => {
    const isDark = useTheme().palette.type === 'dark';
    const {updateTheme} = useThemeSettings()

    useEffect(() => {
        updateTheme(isDark)
    }, [isDark])

    return <Main>
        <TopNav/>
        <MainBody>
            <About/>
            <Education/>
            <Work/>
            <Skills/>
            <ContactMe/>
        </MainBody>
    </Main>
}
export default Layout;
