import CustomContainer from "../../SharedComponents/CustomContainer";
import {Box, Divider, Grid, Paper, Typography, useMediaQuery} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useEffect} from "react";
import {InitWOW} from "../../Utils";

const useStyles = makeStyles(theme => {
    return {
        paper: {
            backgroundColor: theme.palette.primary.light,
            minHeight: 'max(30%, 300px)'
        }
    }
})

const Education = () => {
    useEffect(() => {
        InitWOW()
    }, [])
    const classes = useStyles()
    return <Paper id={'education'} className={classes.paper} elevation={0} square>
        <CustomContainer>
            <Box py={3} className={'wow bounceInDown'} data-wow-delay={"0.5s"}>
                <Typography variant={'h4'} fontWeight={'bold'} color={'primary'}>
                    Education
                </Typography>
                <Grid container my={2} spacing={1}>
                    <Grid item xs={12} sm={4}>
                        <Typography color={'textSecondary'} fontWeight={'bold'}>
                            SSC / Matriculation (Science)
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <Typography color={'textSecondary'}>
                            Govt. Jamia Chishtia High School Faisalabad
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography color={'textSecondary'} fontWeight={'bold'}>
                            2012-2014
                        </Typography>

                    </Grid>

                </Grid>

                <Grid container my={2} spacing={1}>
                    <Grid item xs={12} sm={4}>
                        <Typography color={'textSecondary'} fontWeight={'bold'}>
                            HSSC / Intermediate (I.C.S)
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <Typography color={'textSecondary'}>
                            Govt. Post Graduated College of Science Faisalabad
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography color={'textSecondary'} fontWeight={'bold'}>
                            2014-2016
                        </Typography>

                    </Grid>

                </Grid>

                <Grid container my={2} spacing={1}>
                    <Grid item xs={12} sm={4}>
                        <Typography color={'textSecondary'} fontWeight={'bold'}>
                            Bachelor of science In Computer Science (BSCS)
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <Typography color={'textSecondary'}>
                            Virtual University of Pakistan Faisalabad
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography color={'textSecondary'} fontWeight={'bold'}>
                            2016-2020
                        </Typography>

                    </Grid>
                </Grid>

            </Box>
            <Divider/>
        </CustomContainer>
    </Paper>
}

export default Education
