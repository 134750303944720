import {Box, Divider, Table, TableBody, TableCell, TableRow, Typography, useMediaQuery} from '@mui/material'
import {withStyles} from "@mui/styles";
import {useEffect} from "react";
import {InitWOW} from "../../../../Utils";


const CustomTableCell = withStyles(() => {
    return {
        root: {
            borderBottom: 'none'
        }
    }
})(TableCell)

const CareerGoal = () => {
    useEffect(() => {
        InitWOW()
    }, [])

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'))

    return <Box mt={'max(2rem, 5%)'}>
        <Box textAlign={{md: 'left', xs: 'center'}}>
            <Typography className={'wow bounceIn'} data-wow-delay={'1s'} mb={2} variant={'h4'} fontWeight={'bold'}
                        color={'primary'}>
                About
            </Typography>
            <Typography className={'wow bounceIn'} data-wow-delay={'1s'} mx={{xs: 'auto', md: '0'}}
                        color={'textSecondary'} maxWidth={'75ch'}>
                I am JavaScript/Typescript web developer having experienced with (MERN Stack) Mongo DB, Express,
                React.js, Node.js and MySQL and (MEAN stack) Mongo DB, Express, Angular, Node.js and MySQL. I love to
                write well-tested code, according to modern JavaScript practices & standards. I have experience working
                with HTML, CSS, React.js, React Hooks, JavaScript (ES6/ES7) & Git, MySQL. My experience also includes
                building next generation, responsive Web & mobile applicaltions.
            </Typography>
        </Box>

        <Divider variant={'middle'} sx={{marginBlock: '2rem'}}/>

        <Box>
            <Table>
                <TableBody>
                    <TableRow className={'wow bounceIn'}
                              data-wow-delay={isSmallScreen ? '0.2s' : '1.2s'}>
                        <CustomTableCell>
                            <Typography color={'primary'} fontWeight={'bold'}>D.O.B</Typography>
                        </CustomTableCell>
                        <CustomTableCell>
                            <Typography color={'primary'}>19/09/1997</Typography>
                        </CustomTableCell>
                    </TableRow>
                    <TableRow className={'wow bounceIn'} data-wow-delay={isSmallScreen ? '0.4s' : '1.4s'}>
                        <CustomTableCell>
                            <Typography color={'primary'} fontWeight={'bold'}>Phone</Typography>
                        </CustomTableCell>
                        <CustomTableCell>
                            <Typography color={'primary'}>+923117084083</Typography>
                        </CustomTableCell>
                    </TableRow>
                    <TableRow className={'wow bounceIn'} data-wow-delay={isSmallScreen ? '0.6s' : '1.6s'}>
                        <CustomTableCell>
                            <Typography color={'primary'} fontWeight={'bold'}>Email</Typography>
                        </CustomTableCell>
                        <CustomTableCell>
                            <Typography color={'primary'}>mirzaumair5530@gmail.com</Typography>
                        </CustomTableCell>
                    </TableRow>
                    <TableRow className={'wow bounceIn'} data-wow-delay={isSmallScreen ? "0.8" : '1.8s'}>
                        <CustomTableCell>
                            <Typography color={'primary'} fontWeight={'bold'}>Address</Typography>
                        </CustomTableCell>
                        <CustomTableCell>
                            <Typography color={'primary'}>Faisalabad, Punjab, Pakistan</Typography>
                        </CustomTableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    </Box>
}

export default CareerGoal
