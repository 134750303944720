import {makeStyles} from "@mui/styles";
import React from 'react'
import CustomContainer from "../../SharedComponents/CustomContainer";
import {Box, Divider, Grid, List, ListItem, Paper, Typography as MaterialTypography} from "@mui/material";
import {styled} from '@mui/styles'
import {InitWOW} from "../../Utils";

const Typography = styled(MaterialTypography)(({theme}) => {
    return {
        maxWidth: '55ch'
    }
})

const useStyles = makeStyles(theme => {
    return {
        paper: {
            backgroundColor: theme.palette.primary.light,
            minHeight: 'max(30%, 300px)',
            '& ul': {
                padding: 0,

                '& li': {
                    padding: `0 0 ${theme.spacing(2)} 0`
                }
            }
        }
    }
})
const Work = () => {
    const classes = useStyles()
    React.useEffect(() => {
        InitWOW()
    }, [])
    return <Paper className={classes.paper} elevation={0} square>
        <CustomContainer>
            <Box py={3}>
                <Typography variant={'h4'} fontWeight={'bold'} color={'primary'}>
                    Work
                </Typography>
                <Grid container my={2} spacing={1} className={'wow fadeInLeft'} data-wow-delay="0.5">
                    <Grid item xs={12} sm={4}>
                        <Typography color={'textSecondary'} fontWeight={'bold'}>
                            MERN Stack Developer & MEAN stack Developer
                        </Typography>
                        <Typography color={'textSecondary'} variant={'caption'}>
                            Dmechs Pvt Ltd Lahore (Full Time)
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={5}>

                        <List>
                            <ListItem>
                                <Typography color={'textSecondary'}> Developing and Deploying Full
                                    stack application
                                    using React, Nextjs,Angular, Redux, NodeJS, Nestjs, MongoDB and
                                    MySQL.</Typography>
                            </ListItem>
                            <ListItem>
                                <Typography color={'textSecondary'}> Used npm packages like Material
                                    UI, Angular
                                    material,Ant Design ,moment,
                                    mongoose, sequilize, prisma,
                                    express, multer, S3, bcrypt,
                                    jsonwebtoken and formik.</Typography>
                            </ListItem>
                        </List>

                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography color={'textSecondary'} fontWeight={'bold'}>
                            Nov 2020 to Present
                        </Typography>

                    </Grid>

                </Grid>


            </Box>
            <Divider/>
        </CustomContainer>
    </Paper>
}

export default Work
