import React, {useState} from 'react'
import {AppBar} from "@mui/material";
import CustomToolbar from "../../SharedComponents/CustomToolbar";
import {Tabs, Tab} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import EmailIcon from '@mui/icons-material/Email';
import PsychologyIcon from '@mui/icons-material/Psychology';


const TopNav = () => {
    const [value, setValue] = useState(0)

    const handleChange = (e, newValue) => {
        if (window) {
            const tabsName=['about','education', 'skills', 'contact-me']
            const el = document.querySelector('#' + tabsName[newValue])
            if (el) {
                const y = el.getBoundingClientRect().top + window.scrollY - 72;
                window.scrollTo({top: y})
                setValue(newValue)
            }
        }
    }
    return <AppBar position={'fixed'} elevation={0} color={'transparent'}>
        <CustomToolbar disableGutters varient={'dense'}>
            <Tabs variant={'scrollable'} value={value} scrollButtons={'auto'} onChange={handleChange}
                  aria-label="Top NavBar">
                <Tab icon={<PersonIcon/>} label={'About'} name={'about'} value={0} aria-label={'About'}/>
                <Tab icon={<BusinessCenterIcon/>} label={'Work'} name={'education'} value={1} aria-label={'Work'}/>
                <Tab icon={<PsychologyIcon/>} label={'Skills'} name={'skills'} value={2} aria-label={'Skills'}/>
                <Tab icon={<EmailIcon/>} label={'Contact'} name={'contact-me'} value={3} aria-label={'Contact'}/>
            </Tabs>
        </CustomToolbar>
    </AppBar>
}
export default TopNav
