import React from 'react'
import {withStyles} from "@mui/styles";
import {Paper, Avatar, Box, Typography} from "@mui/material";


const CustomPaper = withStyles(theme => {
    return {
        root: {
            width: '55%',
            height: '70%',

            backgroundColor: 'rgba(255,255,255,0.3)',
            border: `5px solid ${theme.palette.primary.light}`,
            backdropFilter: 'blur(3px)',
            padding: theme.spacing(0.8),

            [theme.breakpoints.down('lg')]: {
                width: '50%',
                height: 'auto'
            },

            [theme.breakpoints.down('md')]: {
                width: '70%',
                height: 'auto',
                margin: 'auto',
            }

        }
    }
})(Paper)

const ProfilePicture = () => {
    return <CustomPaper square>
        <Box flexDirection={'column'} my={'10%'} gap={2} display={'flex'} alignItems={'center'} textAlign={'center'} width={'100%'}
             height={'100%'}>
            <Avatar src={require('../../../../assets/umair.farooq.png')} sx={{    width: 'min(30vw, 10rem)',
                height: 'min(30vw, 10rem)'}}/>
            <Typography variant={'h4'} fontWeight={'bold'} color={'primary'}>
                Umair Farooq
            </Typography>
            <Typography variant={'h6'} color={'textSecondary'}>
                Full Stack Web Developer
            </Typography>
        </Box>
    </CustomPaper>
}

export default ProfilePicture
