import {Box, Grid, Paper, Typography} from "@mui/material";
import CustomContainer from "../../SharedComponents/CustomContainer";
import {makeStyles} from "@mui/styles";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

const useStyles = makeStyles(theme => {
    return {
        paper: {
            minHeight: 'max(30%, 300px)',
            '& ul': {
                padding: 0,

                '& li': {
                    padding: `0 0 ${theme.spacing(2)} 0`
                }
            }
        }
    }
})

const skills = [

    {
        name: 'JavaScript',
        value: 80,
    },
    {
        name: 'TypeScript',
        value: 60,
    },
    {
        name: 'React',
        value: 90,
    },
    {
        name: 'Angular',
        value: 60,

    }, {
        name: 'NextJs',
        value: 60,

    },
    {
        name: 'NodeJS',
        value: 70,
    },
    {
        name: 'NestJs',
        value: 60
    },
    {
        name: 'Python',
        value: 50,
    },
    {
        name: 'HTML',
        value: 95
    },
    {
        name: 'SCSS & CSS',
        value: 90,
    },
    {
        name: 'MySQL',
        value: 60,
    },
    {
        name: 'MongoDB',
        value: 60
    },
    {
        name: 'GraphQL',
        value: 40
    }

]
const Skills = () => {
    const classes = useStyles()
    return <Paper id={'skills'} className={classes.paper} elevation={0} square>
        <CustomContainer>
            <Box py={3}>
                <Typography className={'wow flipInX'} variant={'h4'} fontWeight={'bold'} color={'primary'}>
                    Skills
                </Typography>
                <Grid container my={2} spacing={4}>
                    {
                        skills.map((res, index) => {
                            return <Grid className={'wow fadeInUp'} data-wow-delay={`${index * 0.1}s`} key={index} item
                                         xs={6} sm={4} md={3}>
                                <Box display={'flex'} gap={1} textAlign={'center'}>
                                    <AssignmentTurnedInIcon/>
                                    <Typography color={'secondary'}>
                                        {res.name}
                                    </Typography>
                                </Box>
                            </Grid>
                        })
                    }
                </Grid>
            </Box>
        </CustomContainer>
    </Paper>
}

export default Skills
