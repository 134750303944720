import {Box, Button, Grid, Paper, TextField, Typography, CircularProgress} from "@mui/material";
import CustomContainer from "../../SharedComponents/CustomContainer";
import {makeStyles} from "@mui/styles";
import {Formik, Form} from 'formik'
import React, {useState} from "react";
import * as Yup from 'yup'
import {useThemeSettings} from "../../Contexts/Hooks/useThemeSettings";
import API from '../../Utils/API'
import Toaster from "../../Utils/Toaster";
import {InitWOW} from "../../Utils";

const useStyles = makeStyles(theme => {
    return {
        paper: (props) => {
            return {
                backgroundColor: props.isDark ? '#cfd6db' : '#c0cad1',
                minHeight: 'max(30%, 300px)'
            }
        }
    }
})

const ContactMe = () => {
    React.useEffect(() => {
        InitWOW()
    }, [])

    const {isDark} = useThemeSettings()
    const classes = useStyles(isDark)
    const phoneRegExp = /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm;
    const validationSchema = () => {
        return Yup.object().shape({
            email: Yup.string().email('Invalid email').required('Email is required'),
            name: Yup.string().required('name is required'),
            phone_number: Yup.string().matches(phoneRegExp, 'Invalid Number'),
            message: Yup.string().required('message is required'),

        })
    }

    const [initialState, setInitialState] = useState({
        email: '',
        name: '',
        message: '',
        phone_number: ''
    })

    const [isLoading, setLoading] = useState(false)

    const handleSendMessage = async (values, {resetForm}) => {
        try {
            setLoading(true)
            const res = await API.post('message', values)
            setInitialState({
                email: '',
                name: '',
                message: '',
                phone_number: ''
            })
            resetForm();
            setLoading(false)
            Toaster(res.data.message.message, 'success')

        } catch (e) {
            Toaster('Something went wrong', 'error')
            setLoading(false)
        }

    }


    return <Paper id={'contact-me'} square elevation={0} className={classes.paper}>
        <CustomContainer>
            <Box py={3}>
                <Typography className={'wow fadeInDown'} variant={'h4'} fontWeight={'bold'}
                            color={'primary'}>
                    Contact Me
                </Typography>
                <Formik enableReinitialize initialValues={initialState} onSubmit={handleSendMessage}
                        validationSchema={validationSchema}>
                    {
                        ({handleSubmit, handleChange, handleBlur, values, errors, touched}) => {
                            return <Form onSubmit={handleSubmit}>
                                <Grid container my={2} spacing={4}>
                                    <Grid component={Box} gap={1} display={'flex'} flexDirection={'column'} item xs={12}
                                          md={6}>

                                        <TextField
                                            className={'wow fadeInLeft'}
                                            data-wow-delay={'0.2s'}
                                            fullWidth
                                            variant={'outlined'}
                                            label={'Name'}
                                            required
                                            name='name'
                                            onChange={handleChange}
                                            value={values.name}
                                            error={touched.name && !!errors.name}
                                            helperText={(!!errors.name && touched.name) && errors.name}
                                            onBlur={handleBlur}
                                        />
                                        <TextField
                                            className={'wow fadeInLeft'}
                                            data-wow-delay={'0.4s'}
                                            fullWidth
                                            variant={'outlined'}
                                            label={'Email'}
                                            required
                                            name='email'
                                            onChange={handleChange}
                                            value={values.email}
                                            error={touched.email && !!errors.email}
                                            helperText={(!!errors.email && touched.email) && errors.email}
                                            onBlur={handleBlur}
                                        />
                                        <TextField
                                            className={'wow fadeInLeft'}
                                            data-wow-delay={'0.6s'}
                                            fullWidth
                                            variant={'outlined'}
                                            label={'Phone Number'}
                                            name='phone_number'
                                            type={'tel'}
                                            onChange={handleChange}
                                            value={values.phone_number}
                                            error={touched.phone_number && !!errors.phone_number}
                                            helperText={(!!errors.phone_number && touched.phone_number) && errors.phone_number}
                                            onBlur={handleBlur}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            className={'wow fadeInDown'}
                                            data-wow-delay={'0.8s'}
                                            label={'Message'}
                                            fullWidth
                                            multiline
                                            rows={4}
                                            required
                                            name='message'
                                            onChange={handleChange}
                                            value={values.message}
                                            error={touched.message && !!errors.message}
                                            helperText={(!!errors.message && touched.message) && errors.message}
                                            onBlur={handleBlur}
                                        />

                                        <Box textAlign={'right'} mt={2} className={'wow fadeInUp'}
                                             data-wow-delay={'1s'}>
                                            <Button type={'submit'} fullWidth
                                                    variant={'contained'} color={'primary'}
                                                    disabled={isLoading}>
                                                {
                                                    isLoading ?
                                                        <CircularProgress size={'1.5rem'}/> :
                                                        'Send'}
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        }
                    }
                </Formik>
            </Box>
        </CustomContainer>
    </Paper>
}
export default ContactMe
