import {styled} from '@mui/material/styles'
import {Toolbar} from "@mui/material";
import {grey} from '@mui/material/colors'

const CustomToolbar = styled(Toolbar, {
    shouldForwardProp(propName) {
        return propName
    }
})(({theme}) => {
    return {
        justifyContent: 'center',
        backgroundColor: grey[300],
        '& .MuiTabs-flexContainer': {
            ...theme.mixins.toolbar
        }
    }
})


export default CustomToolbar
