import './App.scss';
import Layout from "./Layout";
import {createTheme, ThemeProvider, responsiveFontSizes} from "@mui/material";
import {ThemeProvider as ThemeProviderLegacy} from "@mui/styles";
import {darkTheme, lightTheme} from "./theme";
import {useThemeSettings} from "./Contexts/Hooks/useThemeSettings";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    const {isDark} = useThemeSettings()
    const theme = createTheme({
        palette: isDark ? darkTheme : lightTheme
    })

    return (
        <ThemeProvider theme={responsiveFontSizes(theme)}>
            <ToastContainer/>
            <ThemeProviderLegacy theme={theme}>
                <Layout/>
            </ThemeProviderLegacy>
        </ThemeProvider>
    );
}

export default App;
