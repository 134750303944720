import {grey, blueGrey} from '@mui/material/colors';


export const darkTheme = {
    type: 'dark',
    primary: {
        main: grey[900],
        dark: grey[800],
        light: grey[400],
    },
    secondary: {
        main: blueGrey[900],
        dark: blueGrey[800],
        light: blueGrey[400],
    },
    background: {
        default: grey[800],
        paper: grey['A700']
    }
};

export const lightTheme = {
    mode: 'light',
    background: {default: grey[200], paper: blueGrey[50]},
    primary: {
        main: grey[900],
        dark: grey[800],
        light: grey[400],
        contrastText: '#fff'
    },
    secondary: {
        main: blueGrey[900],
        dark: blueGrey[800],
        light: blueGrey[400],
        contrastText: '#fff'
    },
    typography:{
        fontFamily:[
            'Montserrat', 'sans-serif'
        ].join(',')
    }
}
